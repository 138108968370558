<!--
 * @Author: your name
 * @Date: 2021-12-07 15:40:36
 * @LastEditTime: 2023-02-10 15:51:28
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 社会责任
 * @FilePath: \new-website-1123\src\views\society\duty\index.vue
-->
<template>
  <div class="service-joinus">
    <!--banner-->
    <div
      class="contactus-us"
      :style="{
        backgroundImage: 'url(' + banner + ')',
        'background-size': 'cover',
      }"
    >
      <div class="contactus-us-view">
        <div class="contactus-us-infor">
          <h2>科技向善 · 智农惠民</h2>
          <h4>积极践行企业社会责任</h4>
        </div>
      </div>
    </div>

    <!--公益计划-->
    <div class="public-spacing-tb opacity-page scale-page" id="socio1">
      <w-title model="公益" modelkey="计划"></w-title>

      <div class="societycase-for">
        <div
          class="societycase-item"
          v-for="(item, index) in societycase"
          :key="index"
          :style="{
            backgroundImage: 'url(' + item.image + ')',
            'background-size': 'cover',
          }"
        >
          <div class="societycase-text">
            <div>
              <h2>{{ item.title }}</h2>
              <h6>{{ item.title_dec }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--校企合作-->
    <div
      class="society-school flexbox opacity-page scale-page"
      :style="{
        backgroundImage: 'url(' + bannertwo + ')',
        'background-size': 'cover',
      }"
      id="socio2"
    >
      <w-title
        model="校企"
        modelkey="合作"
        paragraph="公司已启动校企合作计划，提供大学生就业机会，以及专业化、系统化、实战化的就业再学习"
        :isDetails="false"

      ></w-title>
    </div>

    <!--助农行动-->
    <div class="public-spacing-tb opacity-page scale-page" id="socio3">
      <w-title
        model="助农"
        modelkey="行动"
        paragraph="联动四川扶贫企业-九顶雪，参与支持成都市—阿坝州川西北生态示范区精准扶贫项目。"
      ></w-title>

      <div class="societycase-for">
        <div
          class="societycase-item"
          v-for="(item, index) in agriculture"
          :key="index"
          :style="{
            backgroundImage: 'url(' + item.image + ')',
            'background-size': 'cover',
          }"
        >
          <div class="societycase-text">
            <div>
              <h2>{{ item.title }}</h2>
              <h6>{{ item.title_dec }}</h6>
            </div>
          </div>
        </div>
      </div>

      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/society/business.png"
        class="whoter-img"
      />
    </div>
  </div>
</template>

<script>
import wTitle from "@/components/sTitle/viewtitle";

export default {
  name: "service-joinus",
  components: {
    wTitle,
  },
  data() {
    return {
      banner: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/society/society-pic.jpg",
      bannertwo: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/society/society-pic2.jpg",
      changenamber: 1,
      recruit: [
        {
          position: "建筑设计师",
          position_text: "成都 | 3-5年 | 研发",
          describe: [
            {
              describe_title: "职位描述",
              describe_dec:
                "园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。",
            },
            {
              describe_title: "职位要求",
              describe_dec:
                "园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。园区地块模、作物、设备等建模。",
            },
          ],
        },

        {
          position: "C++后台高级工程师",
          position_text: "重庆 | 3-5年 | 研发",
          describe: [
            {
              describe_title: "职位描述",
              describe_dec:
                "具备良好的分析解决问题能力，能独立承担任务，有系统进度把控能力；责任心强，具有良好的对外沟通和团队协作能力，主动，好学；熟练Unix/Linux下C/C++开发和程序性能调优；",
            },
            {
              describe_title: "职位要求",
              describe_dec:
                "具备良好的分析解决问题能力，能独立承担任务，有系统进度把控能力；责任心强，具有良好的对外沟通和团队协作能力，主动，好学；熟练Unix/Linux下C/C++开发和程序性能调优；",
            },
          ],
        },
        {
          position: "前端工程师",
          position_text: "北京 | 3-5年 | 研发",
          describe: [
            {
              describe_title: "职位描述",
              describe_dec:
                "具备良好的分析解决问题能力，能独立承担任务，有系统进度把控能力；责任心强，具有良好的对外沟通和团队协作能力，主动，好学；熟练Unix/Linux下C/C++开发和程序性能调优；",
            },
            {
              describe_title: "职位要求",
              describe_dec:
                "具备良好的分析解决问题能力，能独立承担任务，有系统进度把控能力；责任心强，具有良好的对外沟通和团队协作能力，主动，好学；熟练Unix/Linux下C/C++开发和程序性能调优；",
            },
          ],
        },
      ],

      societycase: [
        {
          title: "助力耕地质量保护",
          title_dec:
            "建立耕地质量大数据库。由土、水、肥三大耕地类数据组成宏观土壤类数据库，基于人工智能高级算法，进行采集、处理、分析。通过减少化肥用量，增加有机肥用量，提高耕地土壤有机质含量，有效缓解土壤贫瘠化、酸化等问题，全面改善土壤各项理化指标，明显提高禽畜类粪污综合利用率，有效提升产品品质。",
          image: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/society/societycaseimg1.png",
        },
        {
          title: "助力植保绿色防控",
          title_dec:
            "助力推进全国绿色防控建设。按照“绿色植保”理念，采用农业防治、物理防治、生物防治、生态调控以及科学、合理、安全使用农药的技术，达到有效控制农作物病虫害，确保农作物生产安全、农产品质量安全和农业生态环境安全，达到农业增产、增收的目的。",
          image: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/society/societycaseimg2.png",
        },
      ],

      agriculture: [
        {
          title: "上太科技&九顶雪扶贫饮用水",
          title_dec:
            "为贯彻落实省委实施“一干多支”发展战略，构建“一干多支、五区协同”区域发展新格局，九顶雪饮用水项目成为成都市—阿坝州川西北生态示范区精准扶贫目。本公司与九顶雪饮用水合作，共同支持阿坝州川西北生态示范区精准扶贫项目，为农业可持续发展贡献科技力量。",
          image: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/society/societycaseimg3.png",
        },
      ],
    };
  },
  created() {},
  computed: {
    currentDevice() {
      return this.deviceList.filter((item) => item.key == this.deviceActive)[0];
    },
  },
  methods: {
    dataListopen(index) {
      var self = this;
      self.changenamber = index;
    },
    goAnchor(selector) {
      let anchor = this.$el.querySelector(selector);
      document.documentElement.scrollTop = anchor.offsetTop;
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.goAnchor(window.location.hash);
    });
  },
  watch: {
    $route() {
      this.goAnchor(window.location.hash);
    },
  },
};
</script>

<style lang="scss" scoped>
.contactus-us {
  height: 720px;

  .contactus-us-view {
    width: 1200px;
    padding-top: 196px;
    margin: 0 auto;
    .contactus-us-infor {
      color: $color-text;
      text-align: center;
      h2 {
        font-size: 72px;
        margin-bottom: 42px;
      }
      h4 {
        font-size: 28px;
        margin-bottom: 0;
        font-weight: normal;
      }
    }
  }
}

.societycase-for .societycase-item:nth-child(2n) .societycase-text {
  float: right;
}
.whoter-img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.society-school {
  height: 700px;
}
.societycase-item {
  height: 700px;
  margin-bottom: 100px;

  .societycase-text {
    width: 650px;
    text-align: left;
    margin-left: 210px;
    margin-right: 210px;
    padding-top: 205px;
    color: $color-text;
    h2 {
      font-size: 40px;
      margin-bottom: 60px;
    }
    h6 {
      font-size: 24px;
      font-weight: normal;
      line-height: 40px;
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .contactus-us {
    height: 6.82rem;
    background-position: center center;
    .contactus-us-view {
      width: 100%;
      padding-top: 3.12rem;
      .contactus-us-infor {
        h2 {
          font-size: 0.53rem;
          margin-bottom: 0.22rem;
          font-weight: normal;
        }
        h4 {
          font-size: 0.27rem;
        }
      }
    }
  }

  .societycase-for {
    width: 86.4%;
    margin: 0 auto;
    .societycase-item {
      padding-top: 5.4rem;
      margin-bottom: 0.73rem;
      background-size: 240% 5.4rem !important;
      background-repeat: no-repeat;
      background-position: right -2px top -2px;
      background-color: rgba(255, 255, 255, 0.05);
      height: 8.67rem;
      .societycase-text {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0.31rem;
        padding-right: 0.31rem;
        padding-top: 0;
        h2 {
          font-size: 0.31rem;
          padding-top: 0.3rem;
          margin-bottom: 0.27rem;
        }
        h6 {
          font-size: 0.24rem;
          line-height: 0.35rem;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }

  .societycase-for .societycase-item:nth-child(2n) {
    background-position: left top;
  }
  .societycase-for .societycase-item:nth-child(2n) .societycase-text {
    float: none;
  }

  .whoter-img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
  .society-school {
    height: 5.45rem;
    margin-top: 2rem;
    .w-title-box {
      transform: translateY(-160%);
    }
  }
  .w-title-box {
    width: 86.4%;
    margin: 0 auto;
  }
}
</style>
