var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"service-joinus"},[_c('div',{staticClass:"contactus-us",style:({
      backgroundImage: 'url(' + _vm.banner + ')',
      'background-size': 'cover',
    })},[_vm._m(0)]),_c('div',{staticClass:"public-spacing-tb opacity-page scale-page",attrs:{"id":"socio1"}},[_c('w-title',{attrs:{"model":"公益","modelkey":"计划"}}),_c('div',{staticClass:"societycase-for"},_vm._l((_vm.societycase),function(item,index){return _c('div',{key:index,staticClass:"societycase-item",style:({
          backgroundImage: 'url(' + item.image + ')',
          'background-size': 'cover',
        })},[_c('div',{staticClass:"societycase-text"},[_c('div',[_c('h2',[_vm._v(_vm._s(item.title))]),_c('h6',[_vm._v(_vm._s(item.title_dec))])])])])}),0)],1),_c('div',{staticClass:"society-school flexbox opacity-page scale-page",style:({
      backgroundImage: 'url(' + _vm.bannertwo + ')',
      'background-size': 'cover',
    }),attrs:{"id":"socio2"}},[_c('w-title',{attrs:{"model":"校企","modelkey":"合作","paragraph":"公司已启动校企合作计划，提供大学生就业机会，以及专业化、系统化、实战化的就业再学习","isDetails":false}})],1),_c('div',{staticClass:"public-spacing-tb opacity-page scale-page",attrs:{"id":"socio3"}},[_c('w-title',{attrs:{"model":"助农","modelkey":"行动","paragraph":"联动四川扶贫企业-九顶雪，参与支持成都市—阿坝州川西北生态示范区精准扶贫项目。"}}),_c('div',{staticClass:"societycase-for"},_vm._l((_vm.agriculture),function(item,index){return _c('div',{key:index,staticClass:"societycase-item",style:({
          backgroundImage: 'url(' + item.image + ')',
          'background-size': 'cover',
        })},[_c('div',{staticClass:"societycase-text"},[_c('div',[_c('h2',[_vm._v(_vm._s(item.title))]),_c('h6',[_vm._v(_vm._s(item.title_dec))])])])])}),0),_c('img',{staticClass:"whoter-img",attrs:{"src":"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/society/business.png"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contactus-us-view"},[_c('div',{staticClass:"contactus-us-infor"},[_c('h2',[_vm._v("科技向善 · 智农惠民")]),_c('h4',[_vm._v("积极践行企业社会责任")])])])}]

export { render, staticRenderFns }