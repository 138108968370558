<!--
 * @Author: your name
 * @Date: 2021-12-04 15:24:47
 * @LastEditTime: 2021-12-30 14:27:23
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\components\sTitle\title.vue
-->
<template>
  <div class="w-title-box" :class="{ left: direction == 'left' }" >
    <div class="w-title-title" :class="{center: direction == 'center'}">
    <h2 class="w-title-model">
      {{ model }}<span>{{ modelkey }}</span>
    </h2>
    <span v-show="isDetails" class="details" @click="toMore">了解更多</span>
  </div>
    <h4 class="w-title-paragraph" v-if="paragraph">
      {{ paragraph }}
    </h4>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    model: {
      type: String,
      default: "",
    },
    modelkey: {
      type: String,
      default: "",
    },
    paragraph: {
      type: String,
      default: "",
    },
    direction: {
      type: String,
      default: "",
    },
    isDetails: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    toMore() {
      this.$router.push({
        path: "/news/more",
        query: {
          type:`${this.model}${this.modelkey}`,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.w-title-box {
  text-align: center;
  color: $color-active;
  overflow-y: auto;
  .w-title-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.center {
    justify-content: center;
  }
    .details {
      cursor: pointer;
    }
  }
  .w-title-model {
    font-size: 60px;
    margin-bottom: 48px;
    span {
      color: $color-active;
    }
  }
  .w-title-paragraph {
    width: 35.6vw;
    margin-right: 40px;
    line-height: 36px;
    font-size: 22px;
    margin: 0 auto;
    margin-bottom: 68px;
    color: #FFFFFF;
    font-size: 16px;
  }
  &.left {
    text-align: left;
  }

}

@media only screen and (max-width: 1300px){
  .w-title-paragraph {
    width: 30.6vw !important;
  }
}
@media only screen and (max-width: 1000px){
  .w-title-paragraph {
    width: 24.6vw !important;
  }
}
@media only screen and (max-width: 800px) {
  .w-title-box {
    .w-title-model {
      font-size: 0.42rem;
      margin-bottom: 0.36rem;
    }
    .w-title-paragraph {
      font-size: 0.24rem;
      width: 5rem !important;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-bottom: 0.59rem;
      line-height: 0.35rem;
    }
  }
}
</style>
